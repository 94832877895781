<template>
  <div style="margin-left: 25px; margin-right: 25px;padding-bottom: 10px;">
    <PlayoutDashboard :kNumber="kNumber" :incomingRouteRequest="incomingRouteRequest" :hideTargets="true"/>
  </div>
</template>

<script>
// Import dashboard components
import PlayoutDashboard from '@/views/dashboard/reporting/playoutComponents/PlayoutDashboard.vue'

export default {
  name: 'ReportPlayout',
  props: {
    campaigns: {
      type: Array,
      required: true
    },
    kNumber: {
      type: String,
      required: false
    },
    incomingRouteRequest: {
      type: Object,
      required: false
    }
  },
  data: () => ({
  
  }),
  components: {
    PlayoutDashboard
  },
  mounted() {
   
  },
  computed: {

  },

  methods: {

  }
}
</script>