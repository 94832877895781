<template>
    <div>
    <v-row 
        class="px-4 py-1"
        no-gutters 
        style="gap: 1em"
    >
         <!-- Search Input -->
        <v-col cols="4" >
            <v-text-field
                autofocus
                v-model="searchCampaign"
                outlined
                label="Search"
                style="width: 100%"
                append-icon="mdi-magnify"
            >
            <template #append>
            <v-icon
                v-if="searchCampaign"
                color="primary"
                class="mr-2"
                @click="searchCampaign = ''"
            >
                mdi-close
            </v-icon>
            </template>
            </v-text-field>
        </v-col>
        <!-- Select button to choose page limit-->
        <v-col cols="1">
            <v-select
                v-model="itemsPerPage"
                :items="itemsPerPageOptions"
                label="Page Size"
                outlined
                style="width: 100%"
                @change="getReportJobProgress(); gettingReportsLoader = true;"
            ></v-select>
        </v-col>
        <!-- View Type -->
        <v-tooltip  top>
            <template v-slot:activator="{ on }">
              <v-hover v-slot="{ hover }">
                <v-btn
                  large
                  v-on="on"
                  icon
                  :color="hover || reportMode ? 'primary' : 'white'"
                  @click="
                    reportMode = !reportMode
                    getReportJobProgress();
                    gettingReportsLoader = true
                "
                  :loading="gettingReportsLoader"
                >
                  <v-icon
                  large
                  >
                    mdi-account
                  </v-icon>
                </v-btn>
              </v-hover>
            </template>
            <span>Show All/Personal</span>
        </v-tooltip>
        <!-- Refresh -->
        <v-tooltip  top>
            <template v-slot:activator="{ on }">
              <v-hover v-slot="{ hover }">
                <v-btn
                  large
                  v-on="on"
                  icon
                  :color="hover || reportMode ? 'primary' : 'white'"
                  @click="getReportJobProgress(); gettingReportsLoader = true;"
                  :loading="gettingReportsLoader"
                >
                  <v-icon
                  large
                  >
                    mdi-refresh
                  </v-icon>
                </v-btn>
              </v-hover>
            </template>
            <span>Refresh</span>
        </v-tooltip>
        <!-- Upload Button -->
        <div
            @dragover.prevent
            @drop.prevent
            style="width: 150px;"
            @click="$refs.reportsUpload.click()"
            class="ml-auto"
        >
            <input
                @click="$refs.reportsUpload.value = ''"
                id="reportsUpload"
                ref="reportsUpload"
                type="file"
                multiple
                hidden
                @change="uploadFile"
            />
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                        <div
                            ref="dragDiv"
                            v-bind="attrs"
                            v-on="on"
                            @drop="dragFile"
                            @dragleave="$refs.dragDiv.style.backgroundColor = 'transparent'"
                            @dragover="$refs.dragDiv.style.backgroundColor = 'grey'"
                            style="
                                background-color: transparent;
                                padding: 0.66em 0em;
                                transition: 0.1s;
                            "
                            :style="hover ? {border: 'solid 1px rgba(255,255,255, .8)'} : {border: 'solid 1px rgba(255,255,255, .3)'}"
                            class="rounded"
                        >
                          <div style="width:100%; text-align:center;">
                            <v-icon large>mdi-cloud-upload</v-icon>
                          </div>
                        </div>
                    </v-hover>
                </template>
                <span>Drag/Drop Reports</span>
            </v-tooltip>
        </div>
    </v-row>
    <v-data-table
        :headers="reportJobHeaders"
        :items="reportJobs()"
        :items-per-page="itemsPerPage"
        hide-default-footer
        :expanded.sync="expanded"
        show-expand
    >
        <!-- Status -->
        <template v-slot:item.statusLine="{ item }">
            <td width="2px" class="d-flex justify-between align-center ml-n4" :style="{ width:'2px', backgroundColor: statusColor(item.mode) }"></td>
        </template>
        <!-- Expanded item info -->
        <template v-slot:expanded-item="{ item }">   
            <td colspan="10" v-if="reportJobStatus(item).status === 'processed'">
                <div v-html="item.htmlMessage"></div>
            </td>
            <td colspan="10" v-else>
                <p>No more info at this stage</p>
            </td>
        </template>
        <!-- FileName -->
        <template v-slot:item.fileName="{ item }">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                        <small 
                            @click="downloadItem(item)"
                            v-bind="attrs"
                            v-on="on"
                            style="cursor: pointer;"
                            :style="hover ? {textDecoration: 'underline'} : {textDecoration: 'none'}"

                        >{{item.media.originalFileName}}</small>
                    </v-hover>
                </template>
                <span>Download report</span>
            </v-tooltip>
            
        </template>
        <!-- Progress bars -->
        <template v-slot:item.progress="{ item }">
            <v-progress-linear
            color="primary"
            rounded
            height="20"
            :value="reportJobStatus(item).percentage"
            class="mt-2"
            ></v-progress-linear>
            <small ><pre>{{reportJobStatus(item).message}}</pre></small>
        </template>
        <!-- Media Owners Select -->
        <template v-slot:item.mediaOwner="{ item }">
            <v-select
                v-if="item.mode==='prepare'"
                v-model="item.mediaOwner"
                outlined
                :items="mediaOwners"
                label="Media Owner*"
                :rules="requiredRule"
                item-text="friendlyName"
                return-object
                :dense="true"
            />
            <span v-else-if="item.mediaOwner"><small>{{item.mediaOwner.friendlyName}}</small></span>
        </template>
        <!-- Campaign Select -->
        <template v-slot:item.campaign="{ item }">
            <v-select
                v-if="item.mode==='prepare'"
                v-model="item.campaign"
                outlined
                :items="campaigns"
                label="Campaign"
                :rules="requiredRule"
                item-text="name"
                return-object
                :dense="true"
            />
            <span v-else-if="item.campaign"><small>{{item.campaign.name}}</small></span>
        </template>
        <!-- Item Actions -->
        <template v-slot:item.actions="{ item }">
            <v-btn
                v-if="reportJobStatus(item).status === 'uploaded'"
                icon
                color="primary"
                @click="$emit('uploadPreparedJobsChild', item)"
                :loading="playLoadingAnimation"
            >
                <v-icon large> mdi-play </v-icon>
            </v-btn>
            <v-btn
                v-if="reportJobStatus(item).status === 'cancelled' || reportJobStatus(item).status === 'deleted' || reportJobStatus(item).status === 'exists'"
                icon
                color="primary"
                @click="purgePreparedJob(item)"
            >
                <v-icon large> mdi-skull </v-icon>
            </v-btn>
            <v-btn
                v-if="reportJobStatus(item).status === 'uploading' || reportJobStatus(item).status === 'uploaded'"
                icon
                color="primary"
                @click="deletePreparedJob(item)"
            >
                <v-icon large> mdi-delete </v-icon>
            </v-btn>
        </template>
    </v-data-table>
    </div>
</template>
<script>
  import ReportingController from '@/services/controllers/Reporting'
  import UserController from '@/services/controllers/User'
  import moment from 'moment'
  // Mixins
  import collectionSearch from '@/mixins/collection/search'
  import axios from 'axios'
    export default {
        name: 'ReportProgress',
        props: {
            mediaOwners: {
                type: Array,
                required: true
            },
            campaigns: {
                type: Array,
                required: true
            },
            uploadedReports: {
                type: Array,
                default: () => []
            },
            preparedReportJobs: {
                type: Array,
                required: true
            },
            requiredRule: {
                type: Array,
                required: true
            },
            uploadSpeed: {
                type: Number,
                required: true
            },
            playLoadingAnimation: {
                type: Boolean,
                required: true
            }
        },
        data: () => ({
            // Headers and rules
            headers: [
                {
                    text: 'Campaign Name',
                    align: 'start',
                    sortable: false,
                    value: 'campaignName',
                    width: '18%'
                },
                { text: 'Burst', value: 'burst', sortable: false, width: '18%' },
                { text: 'Screen', value: 'screen', sortable: false, width: '18%' },
                { text: 'Media Owner', value: 'mediaOwner', sortable: false, width: '18%' },
                { text: 'AdCopyID/CreativeID', value: 'adCopyId', sortable: false, width: '28%' }
            ],
            reportJobHeaders: [
                {
                text: '',
                value: 'statusLine',
                sortable: false,
                },
                {
                text: 'File Name',
                value: 'fileName',
                sortable: false,
                },
                {
                text: 'Media Owner',
                value: 'mediaOwner',
                sortable: false,
                },
                {
                text: 'Campaign',
                value: 'campaign',
                sortable: false,
                },
                {
                text: 'Progress',
                value: 'progress',
                sortable: false,
                },
                {
                text: 'Status',
                value: 'status',
                sortable: false,
                },
                {
                text: 'Actions',
                value: 'actions',
                sortable: false,
                },
            ],
            existingReportJobs: [],
            // Upload progress
            rujInterval: false,
            callWaiting: false,
            // Expanded rows
            expanded: [],
            // Search Bar
            searchCampaign: '',
            reportMode: false,
            userId: null,
            // Loader
            gettingReportsLoader: false,
            // Pagination
            itemsPerPageOptions: [10, 20, 50, 100],
            itemsPerPage: 20,

            // Search Params
            searchParams:
            [
                { name: 'mediaOwner.friendlyName', weight: 2 },
                { name: 'media.originalFileName', weight: 1.5 },
                { name: 'mode', weight: 0.2 }
            ],
        }),
        beforeDestroy() {
            this.rujInterval = false;
        },
        inject: ['uploadFile', 'dragFile'],
        created() {
            // Redirect if no permissions
            if(!this.$store.state.Permissions.reportingUpload && !this.$store.state.Permissions.reportingLinking)
                this.$router.push({ name: 'Campaigns' })
            // Get existing report jobs
            this.userDetails()

            // detect if this page loses focus, don't ask for report data (minimise calls to server)
            //window.addEventListener('focus', this.turnRujIntervalOn);
            //window.addEventListener('blur', this.turnRujIntervalOff);
            //window.addEventListener('pageshow', this.turnRujIntervalOn);
            //window.addEventListener('pagehide', this.turnRujIntervalOff);
        },
        computed: {
            reportJobs() { 
                return () => {
                    let reportJobs = [...this.preparedReportJobs, ...this.existingReportJobs]

                    this.$nextTick()
                    
                    return collectionSearch(reportJobs, this.searchParams, this.searchCampaign)
                };
            },
            reportJobStatus() { return (rj) => {
                // return status object
                let statusObj = { status: "Unknown", message: "", percentage: 0 }
                // get upload speed based on formData upload
                if(rj.mode == "exists")
                {
                    statusObj.status = "exists"
                    statusObj.message = "Job already exists"
                }
                else if(rj.mode == "deleted")
                {
                    statusObj.status = "deleted"
                    statusObj.message = "Deleted by user"
                }
                else if(rj.mode == "cancelled")
                {
                    statusObj.status = "cancelled"
                    statusObj.message = "Cancelled due to interruption"
                    percentage: 0
                }
                else if(rj.media.id > 0 && rj.mode != "prepare")
                {
                    let dateStatus = this.reportJobDateStatus(rj)
                    if(dateStatus == "submitted")
                    {
                        statusObj.status = "submitted"
                        // work out estimated time to start processing
                        let secondsRemaining = Math.round(this.getEstimatedTimeToStartProcessing(rj) + this.getEstimatedTimeToProcess(rj),2)
                        // time since submitted
                        let timeToComplete = this.convertToLocal(rj.dateAdded).add(secondsRemaining,'seconds')
                        statusObj.message = "Submitted: Waiting to process: Estimated to complete, " + timeToComplete.fromNow()
                        // use timetocomplete and seconds remaining to work out percentage (dont use get percetange complete as it uses uploaded size)
                        statusObj.percentage = timeToComplete.diff(moment(),'seconds') / secondsRemaining * 100
                        // reverse percentage
                        statusObj.percentage = 100 - statusObj.percentage
                    }
                    else if (dateStatus == "processing")
                    {
                        statusObj.status = "processing"
                        let secondsRemaining = Math.round(this.getEstimatedTimeToProcess(rj),2)
                        let timeToComplete = this.convertToLocal(rj.dateAdded).add(secondsRemaining,'seconds')
                        statusObj.message = "Processing started " +  this.convertToLocal(rj.dateProcessed).fromNow() + ": Estimated to complete " + timeToComplete.fromNow()
                        statusObj.percentage = timeToComplete.diff(moment(),'seconds') / secondsRemaining * 100
                        // reverse percentage
                        statusObj.percentage = 100 - statusObj.percentage
                    }
                    else if(dateStatus == "processed")
                    {
                        statusObj.status = "processed"
                        // any extra info?
                        let extraInfo = ""
                        // are there any existing jobs still in the submitted state or processing state?
                        const timeToConsolidateSecondsMagicNumber = 60 * 5 // 5 minutes
                        let reportJobs = [...this.preparedReportJobs, ...this.existingReportJobs]
                        let existingJobs = reportJobs.filter(j => {
                            let status = this.reportJobDateStatus(j)
                            return status == "submitted" || status == "processing"
                        })
                        // add a 5 minute buffer to the time moment
                        let timeToConsolidation = this.convertToLocal(rj.dateCompleted).utc().add(timeToConsolidateSecondsMagicNumber,'seconds')
                        // if there are no existing jobs and the time to consolidation is in the future
                        if(existingJobs.length == 0 && moment().diff(timeToConsolidation) < 0)
                            extraInfo = " " + moment(timeToConsolidation).fromNow()
                        statusObj.message = extraInfo ? "Loading AdCopyID’s, ready" + extraInfo + " \nProcessed " + this.convertToLocal(rj.dateCompleted).fromNow() : "Ready to Link"+ " \nProcessed " + this.convertToLocal(rj.dateCompleted).fromNow()
                        // work out percentage complete
                        let totalTime = moment(timeToConsolidation).diff(this.convertToLocal(rj.dateCompleted),'seconds')
                        // time taken  
                        let timeTaken = moment().diff(this.convertToLocal(rj.dateCompleted),'seconds')
                        //Convert time in seconds
                        statusObj.percentage = this.getPercentageComplete(totalTime, timeTaken)
                    }
                }
                else
                {
                    let secondsRemaining = (rj.sizeRemaining / this.uploadSpeed)
                    if(secondsRemaining == 0)
                    {
                        statusObj.status = "uploaded"
                        statusObj.message = "Ready to upload";
                        statusObj.percentage = 100
                    }
                    else
                    {
                        statusObj.status = "uploading"
                        statusObj.message = "Reading the file... " + Math.round(secondsRemaining,2) + " seconds remaining"
                        statusObj.percentage = this.getPercentageComplete(rj.uploadedReport.size, rj.sizeRemaining)
                        // reverse percentage
                        statusObj.percentage = 100 - statusObj.percentage
                    }
                }
                return statusObj;
            }},
        },
        methods: {
            turnRujIntervalOn() { try {if(!this.rujInterval && !this.callWaiting) { setTimeout(() => this.getReportJobProgress(),2000);} this.rujInterval = true;} catch(e) {} },
            turnRujIntervalOff() { try { this.rujInterval = false;} catch(e) {} },

            async downloadItem({ media }) {
                let downloadUrl
                let downloadFileName
                let mimeType
                downloadUrl = media.url
                downloadFileName = media.originalFileName
                mimeType = media.mimeType
                let cachebuster = Math.round(new Date().getTime() / 1000);
                const response = await axios.get(downloadUrl +"?rn="+cachebuster, { responseType: "blob" });
                const blob = new Blob([response.data], { type: mimeType });
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = downloadFileName;
                link.click();
                URL.revokeObjectURL(link.href);
            },
            getPercentageComplete(totalTime, timeTaken){
                if(timeTaken >= totalTime) return 100
                else return timeTaken/totalTime * 100    
            },
            statusColor(mode) {
                switch(mode) {
                case "success":
                    return 'green';
                case "failure":
                    return 'red';
                case "warning":
                    return 'orange';
                default:
                    return 'white';
                }
            },
            getEstimatedTimeToStartProcessing(reportJob)
            {
                let rj = reportJob
                // get the status of the reportJob by date
                let dateStatus = this.reportJobDateStatus(rj)
                // get a list of items in the submitted state
                let submittedReportJobs = this.reportJobs().filter(rj => this.reportJobDateStatus(rj) == "submitted").sort((a,b) => moment(a.dateAdded).diff(moment(b.dateAdded)))
                // get the position of the reportJob in the submittedReportJobs
                let position = submittedReportJobs.findIndex(rj => rj.id == reportJob.id)
                // loop over all the reports before this job and add up the estimated time
                let secondsRemaining = 0
                for(let i = 0; i < position; i++)
                    secondsRemaining += this.getEstimatedTimeToProcess(submittedReportJobs[i])
                secondsRemaining+= 15
                return secondsRemaining
            },

            getEstimatedTimeToProcess(reportJob) {
                let rj = reportJob
                // get the status of the reportJob by date
                this.reportJobDateStatus(rj)
                // get the estimated time remaining depending on the filesize and the file extension
                let secondsRemaining = 0
                const xlsMagicNumber = 0.060 // X ms a byte
                const csvMagicNumber = 0.040 // X ms a byte
                if(rj.media.originalFileName.toLowerCase().indexOf(".csv") > -1)
                    secondsRemaining = (rj.media.fileSize * csvMagicNumber) / 1000
                else //if(rj.media.originalFileName.toLowerCase().indexOf(".xls") > -1)
                    secondsRemaining = (rj.media.fileSize * xlsMagicNumber) / 1000
                return secondsRemaining
            },

            reportJobDateStatus(reportJob) {
                let dateProcessed = moment(reportJob.dateProcessed) // don't convert to local to check
                let dateCompleted = moment(reportJob.dateCompleted)
                let begginingOfTime = moment("0001-01-01")
                if(reportJob.id > 0)
                {
                    if(dateProcessed.isSame(begginingOfTime)) return "submitted"
                    else if (dateCompleted.isSame(begginingOfTime)) return "processing"
                    else return "processed"
                }
                return "unknown";
            },
            // Get user id
            userDetails () {
                UserController.whoAmI()
                .then((res) => {
                    this.userId = res.data.id
                    this.rujInterval = true;
                    this.getReportJobProgress() // calls itself -- time increased to 1 min // opted for button too
                })
            },
            getReportJobProgress()  {
                if(!this.rujInterval) return;
                if(this.callWaiting) return;
                this.callWaiting = true;
                let reportType = this.reportMode ? -1 : this.userId
                ReportingController.getReportJobProgress(reportType, this.itemsPerPage)
                .then((res) => {
                    // replace newly converted prepared report jobs to existing report jobs
                    for(let index in res.data)
                    {
                        let rj = res.data[index]
                        let preparedRj = this.preparedReportJobs.find(rj2 => rj2.id == rj.id)
                        if(preparedRj)
                        {
                            let index = this.preparedReportJobs.indexOf(preparedRj)
                            this.preparedReportJobs[index] = rj
                        }
                    }
                    this.existingReportJobs = res.data
                    this.existingReportJobs = this.existingReportJobs.filter(rj => !this.preparedReportJobs.some(rj2 => rj2.id == rj.id))
                    this.gettingReportsLoader = false
                    this.$nextTick()
                    let waitTime = 60000+(this.itemsPerPage*200) // 1min + 200ms per item extra
                    setTimeout(() => this.getReportJobProgress(),waitTime) // just to even things out // opted for button too
                    this.callWaiting = false;
                })
                .catch((err) => {
                    this.callWaiting = false;
                    this.$root.$emit('snackbarError', err)
                })
            },
            purgePreparedJob(reportJob) {
                let index = this.preparedReportJobs.findIndex(x => x.guid === reportJob.guid)
                this.preparedReportJobs.splice(index, 1)
            },
            deletePreparedJob(reportJob) {
                reportJob.mode = "deleted"
            },

            convertToLocal(utcDatetime) {

                if(process.env.VUE_APP_DELIVER_SERVER_TIMEZONE != "UTC") return moment(utcDatetime)

                // Convert UTC datetime to the system's timezone
                const systemTimezoneOffset = moment().format('Z');
                const convertedDatetime = moment.utc(utcDatetime).utcOffset(systemTimezoneOffset);

                return convertedDatetime;
            }
        }
    }
</script>