<template>
  <v-container>
    <!-- HEADER -->
    <v-row>
      <v-col
        cols="12"
        class="mt-3 pa-4 text-left"
      >
        <v-icon
          class="mb-4"
          large
        >
          mdi-file-chart
        </v-icon>
        <span class="text-h2 ml-2">Reporting Setup</span>
      </v-col>
    </v-row>
    <!-- TABS -->
    <template>
      <v-card>
        <v-tabs
          v-model="tab"
          background-color="primary"
          dark
        >
          <v-tab
            v-for="item in computedTabs"
            :key="item.id"
          >
            <v-icon class="mr-2">{{ item.icon }}</v-icon>{{ item.name }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="item in computedTabs"
            :key="item.id"
          >
            <v-card flat>
              <component 
                :is="item.component"
                :mediaOwners="mediaOwners"
                :campaigns="campaigns"
                :uploadedReports:="uploadedReports"
                :preparedReportJobs="preparedReportJobs"
                @preparedReportJobs="preparedReportJobs"
                :existingReportJobs="existingReportJobs"
                :requiredRule="requiredRule"
                :uploadSpeed="uploadSpeed"
                @uploadPreparedJobsChild="uploadPreparedJob"
                :playLoadingAnimation="playLoadingAnimation"
              />
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </template>
  </v-container>
</template>
<script>
  import ReportingController from '@/services/controllers/Reporting'
  import ScreenController from '@/services/controllers/Screen'
  import CampaignController from '@/services/controllers/Campaign'
  import 'vue2-dropzone/dist/vue2Dropzone.min.css'
  import ReportLinkingVue from './ReportLinking.vue'
  import ReportProgressVue from './ReportProgress.vue'
  import ReportGenerationVue from './ReportGeneration.vue'
  import ReportEmailDigestVue from './ReportEmailDigest.vue'
  import ReportPlayoutVue from './ReportPlayoutVue.vue'


  export default {
    data: () => ({
      campaigns: [],
      uploadedReports: [],
      mediaOwners: [],
      selectedMediaOwner: null,
      requiredRule: [(v) => !!v || 'Field is required'],
      isUploading: 0,
      hasUploaded: 0,
      existingReportJobs: [],
      preparedReportJobs: [],
      uploadSpeed: 1, // kbps: assume an extremely slow speed initially
      tab: null,
      tabs: [],
      playLoadingAnimation: false,
    }),
    components: {
      ReportLinkingVue,
      ReportProgressVue,
      ReportGenerationVue,
      ReportEmailDigestVue,
      ReportPlayoutVue
    },
    created () {
      if(!this.$store.state.Permissions.reportingUpload && !this.$store.state.Permissions.reportingLinking && !this.$store.state.Permissions.reportingPlayoutScheduleGenerate && !this.$store.state.Permissions.reportEmailDigest)
        this.$router.push({ name: 'Campaigns' })

      this.getMediaOwners()
      this.getAllCampaigns()
    },
    mounted() {
      // setup tabs
      this.tabs = [
        {
          name: 'Report Progress',
          id: 0,
          icon: 'mdi-upload',
          component: ReportProgressVue,
          permission: this.$store.state.Permissions.reportingUpload
        },
        {
          name: 'Report Linking',
          id: 1,
          icon: 'mdi-link',
          component: ReportLinkingVue,
          permission: this.$store.state.Permissions.reportingLinking
        },
        {
          name: 'Report Generation',
          id: 2,
          icon: 'mdi-cog',
          component: ReportGenerationVue,
          permission: this.$store.state.Permissions.reportingPlayoutScheduleGenerate
        },
        {
          name: 'Report Email Digest',
          id: 3,
          icon: 'mdi-email-fast-outline',
          component: ReportEmailDigestVue,
          permission: this.$store.state.Permissions.reportEmailDigest
        },
        {
          name: 'Playout Report',
          id: 4,
          icon: 'mdi-play',
          component: ReportPlayoutVue,
          permission: this.$store.state.Permissions.playoutTabAllCampaigns
        },
      ];

      let localStoragePreparedReportJobs = localStorage.getItem("verify_prepared_ReportJobs");
      this.preparedReportJobs = []
      if(localStoragePreparedReportJobs != "undefined" && localStoragePreparedReportJobs != null && localStoragePreparedReportJobs != "")
      {

        this.preparedReportJobs = JSON.parse(localStoragePreparedReportJobs);
        // filter out already submitted reports
        this.preparedReportJobs = this.preparedReportJobs.filter(rj => rj.id == undefined)
        for(let index in this.preparedReportJobs)
        {
          let rj = this.preparedReportJobs[index]
          if(rj.mode == "prepare" && rj.media.id == 0)
            rj.mode = "cancelled"
        }
      }
    },
    beforeDestroy() {

      // remove deleted + cancelled report jobs
      this.preparedReportJobs = this.preparedReportJobs.filter(rj => rj.mode != "deleted" && rj.mode != "cancelled")

      // add to local storage
      localStorage.setItem("verify_prepared_ReportJobs", JSON.stringify(this.preparedReportJobs));
    },
    provide: function () {
        return {
            uploadFile: this.uploadFile,
            dragFile: this.dragFile,
            updateBurstScreen: this.updateBurstScreen
        }
    },
    computed: {
      computedTabs () {
        // filter if user has permission
        return this.tabs.filter(tab => tab.permission)
      },
    },
    methods: {
      async getAllCampaigns() {
        // Get all campaigns
        const { data = null } = await CampaignController.getAllCampaigns()
        // Catch error
        .catch(error => { this.$root.$emit("snackbarError", error.response.data.message) })
        // If the response contains valid data then append
        if(data) {
          this.campaigns = data.sort((a, b) => a.name.localeCompare(b.name))
          // filter out campaigns which are archived
          this.campaigns = this.campaigns.filter(campaign => campaign.isArchived === false)
        }
        else {
          this.campaigns = []
        }
      },
      resetUploadStats() {
        this.isUploading = 0
        this.hasUploaded = 0
      },
      getMediaOwners() {
        ScreenController.getMOList()
        .then((res) => {
          this.mediaOwners = res.data
          this.mediaOwners.sort((a,b) => a.friendlyName.localeCompare(b.friendlyName))
        })
      },
      uuidv4() {
        return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
          (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
      },
      async uploadReportJob(reportJob){
        // upload the file
        let uploadedReport = reportJob.uploadedReport
        const formData = new FormData()
        formData.append('file', uploadedReport)
        formData.append('feature','ReportUploads')

        const config = {
          onUploadProgress: progressEvent => { 
            // convert unix timestamp to milliseconds
            let timeTaken = (new Date().getTime()) - reportJob.uploadedAt;
            // work out upload speed with bytes uploaded and time taken
            this.uploadSpeed = (progressEvent.loaded / timeTaken) * 1000
            reportJob.sizeRemaining = progressEvent.total - progressEvent.loaded
          }
        }

        await CampaignController.uploadMedia(formData,config)
          .then((res) => {
            reportJob.media = res.data
            this.$nextTick()
          })
          .catch((err) => {
          })
          .finally(() =>{
            this.hasUploaded++
          })
          this.isUploading++
      },
      // upload the prepared report jobs
      uploadReportsPrepare() {
        // loop through reports to upload and upload them silently
        for(let uploadedReport of this.uploadedReports){
          // create a guid
          let guid = this.uuidv4()

          // create a report job entry
          let rj = {
            media: { id: 0, originalFileName: uploadedReport.name, fileSize: uploadedReport.size},
            user: {},
            mediaOwner: this.selectedMediaOwner,
            campaign: {id:0, name: "Unassigned"},
            guid: guid,
            uploadedReport: uploadedReport,
            uploadedAt: new Date().getTime(),
            sizeRemaining: uploadedReport.size,
            mode: "prepare"
          }
          this.preparedReportJobs.unshift(rj)

          // we do it this way, so that if it fails, we can retry
          this.uploadReportJob(rj)
        }
      },
      // upload the prepared report jobs
      uploadPreparedJob(reportJob) {
        this.playLoadingAnimation = true
        let mediaId = reportJob.media.id
        let mediaOwnerId = reportJob.mediaOwner ? reportJob.mediaOwner.id : 0
        let campaignId = reportJob.campaign ? reportJob.campaign.id : 0
        // create a report upload job
        const rujAdd = {
          mediaId: mediaId,
          mediaOwnerId: mediaOwnerId,
          campaignId: campaignId
        }

        // upload the file
        ReportingController.uploadReportJob(rujAdd)
          .then((res) => {
            // replace report job from in prepared list with the recieved one
            this.playLoadingAnimation = false
            if(res.data.justAdded == false) reportJob.mode = "exists"
            else              {
              let index = this.preparedReportJobs.findIndex(x => x.guid === reportJob.guid)
              this.preparedReportJobs.splice(index, 1, res.data)
            }
          })
          .catch((err) => {
            this.$root.$emit('snackbarError', err.response.data.message)
            this.playLoadingAnimation = false
          })
      },
      // upload a report job
      uploadFile (e) {
        this.isUploading = 0
        this.hasUploaded = 0
        this.uploadedReports = Object.values(e.target.files)
        this.uploadReportsPrepare()
      },
      // upload a report job
      dragFile (e) {
        this.isUploading = 0
        this.hasUploaded = 0
        this.uploadedReports = Object.values(e.dataTransfer.files)
        this.uploadReportsPrepare()
      },
    },
  }
</script>
