<template v-if="$store.state.Permissions.reportingLinking" >
<div>
    <v-row class="mx-4">
        <!-- Add a resource Link to a Campaign->Burst->Screen -->
        <v-col cols="4">
            <v-combobox
                :items="campaigns"
                item-text="name"
                :item-value="item => item"
                v-model="campaign_id"
                label="Campaign"
                :outlined="true"
                hide-details
                @change="campaignChange"
                @keyup.enter="selectFirst"
                return-object
                style="cursor: pointer;"
                ref="campaignsCombobox"
            />
        </v-col>
        <v-col cols="4">
            <v-combobox
                label="Assigned IDs (To This Campaign)"
                :items="assignedReportLinks"
                :item-value="item => item.adCopyId"
                :item-text="item => item.adCopyId + ((item.comments) ? ' - ' + item.comments : '')"
                outlined
                hide-details
            />
        </v-col>
        <v-col cols="4">
            <v-combobox
                label="Unassigned IDs"
                :items="unassignedReportLinks"
                :item-value="item => item.adCopyId"
                :item-text="item => item.adCopyId + ((item.comments) ? ' - ' + item.comments : '')"
                outlined
                hide-details
            />
        </v-col>
    </v-row>
    <v-row class="mx-4">
       <!-- Search Input -->
       <v-col cols="12">
            <v-text-field
                autofocus
                v-model="searchCampaign"
                outlined
                label="Search"
                style="width: 100%"
                append-icon="mdi-magnify"
            >
            <template #append>
            <v-icon
                v-if="searchCampaign"
                color="primary"
                class="mr-2"
                @click="searchCampaign = ''"
            >
                mdi-close
            </v-icon>
            </template>
            </v-text-field>
        </v-col> 
    </v-row>
    <v-row class="pa-4">
        <v-col cols="12">
            <v-data-table
                v-if="campaignReportLinks"
                :items="itemsSearchFilter"
                :loading='reportLinksLoading || reportUnassignedLinksLoading'
                loading-text="Loading Report Links..."
                :headers="headers"
                hide-default-footer
                disable-pagination
            >
                <template v-slot:header="headers">
                    <th v-for="head in headers.headers" :key="head">{{ head }}</th>
                </template>
                <template v-slot:item="{ item }">
                    <tr>
                    <td>{{ item.campaignBurstScreen.campaignBurst.campaign.name }}</td>
                    <td>{{ item.campaignBurstScreen.campaignBurst.name }}</td>
                    <td>{{ item.campaignBurstScreen.screen.mediaOwner.friendlyName }}</td>
                    <td>{{ item.campaignBurstScreen.screen.name }}</td>
                    <td>
                        <v-autocomplete
                        :value="selectedAdCopyId[item.campaignBurstScreen.id]"
                        multiple
                        label="Report Links"
                        :items="allReportLinksFiltered(item.campaignBurstScreen)"
                        :item-value="item => item.adCopyId"
                        :item-text="item => item.adCopyId + ((item.comments) ? ' - ' + item.comments : '')"
                        @change="checkCreativeId($event,item.campaignBurstScreen.id)"
                        
                        class="mt-2"
                        >
                        <template slot="selection" slot-scope="data">
                            <!-- HTML that describe how select should render selected items -->
                            <v-chip>
                                <span>{{ data.item.adCopyId }}</span>
                                <v-icon class="ml-1" x-small @click.stop="removeChipOpenDialog(data.item.adCopyId , item.campaignBurstScreen.id)">mdi-close</v-icon>
                            </v-chip>
                        </template>
                        </v-autocomplete>
                    </td>
                    </tr>
                </template>
            </v-data-table>
            <span v-else class="text-body-1">No Campaign -> Burst -> Screens assigned to user.</span>
        </v-col>
    </v-row>
    <v-dialog 
        v-model="removeLinkingDialog" 
        max-width="500px"
    >
        <v-card>
            <v-card-title class="headline" style="background-color: #4AA6D5">Remove Linking</v-card-title>
            <v-card-text class="pa-4 text-center">
                <span class="text-body">Are you sure you want to remove this linking?</span>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-col
                cols="12"
                class="text-center"
                >
                    <v-btn
                        color="primary"
                        class="mr-3"
                        @click="removeChip()"
                    >
                        Confirm
                    </v-btn>
                    <v-btn
                        color="red"
                        @click="removeLinkingDialog = false;"
                    >
                        Cancel
                    </v-btn>
                </v-col>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>
<script>
    import ReportingController from '@/services/controllers/Reporting'
    import CampaignController from '@/services/controllers/Campaign'
    import moment from 'moment'
    // Mixins
    import collectionSearch from '@/mixins/collection/search'

    export default {
        name: 'ReportLinking',
        props: {
            mediaOwners: {
                type: Array,
                required: true
            },
            campaigns: {
                type: Array,
                required: true
            },
            uploadedReports: {
                type: Array,
                default: () => []
            },
            preparedReportJobs: {
                type: Array,
                required: true
            },
            requiredRule: {
                type: Array,
                required: true
            },
        },
        data() {
            return {
                campaign: null,
                title: 'Report Linking',
                bursts: [],
                screens: [],
                campaign_id: null,
                burst_id: null,
                screen_id: null,
                selectedAdCopyId: [],
                campaignReportLinks: [],
                unassignedReportLinks: [],
                assignedReportLinks: [],
                allReportLinks: [],
                reportLinksLoading: false,
                reportUnassignedLinksLoading: false,
                // Headers and rules
                headers: [
                    {
                        text: 'Campaign Name',
                        align: 'start',
                        sortable: false,
                        value: 'campaignName',
                        width: '18%'
                    },
                    { text: 'Burst', value: 'burst', sortable: false, width: '18%' },
                    { text: 'Media Owner', value: 'mediaOwner', sortable: false, width: '18%' },
                    { text: 'Screen', value: 'screen', sortable: false, width: '18%' }, 
                    { text: 'AdCopyID/CreativeID', value: 'adCopyId', sortable: false, width: '28%' }
                ],
                removeLinkingDialog: false,
                // Remove link
                selectedChip: null,
                selectedItem: null,
                // Search
                searchCampaign: '',

                // Search Params
                searchParams: [
                    {
                        name: "campaignBurstScreen.campaignBurst.name",
                        weight: 0.5,
                    },
                    {
                        name: "campaignBurstScreen.screen.name",
                        weight: 1,
                    },
                    {
                        name: "campaignBurstScreen.screen.mediaOwner.friendlyName",
                        weight: 1.5,
                    },
                    {
                        name: "campaignReports.creativeId",
                        weight: 2,
                    },
                ]
            }
        },
        computed: {
            filteredCampaignItems() {
                return this.campaigns.filter(item =>
                item.name.toLowerCase().includes(this.campaign_id.toLowerCase())
                );
            },
            allReportLinksFiltered() {
                return (campaignBurstScreen) =>
                {
                var startDate = campaignBurstScreen.startDate ?? campaignBurstScreen.campaignBurst.startDate ?? campaignBurstScreen.campaignBurst.campaign.startDate;
                var endDate = campaignBurstScreen.endDate ?? campaignBurstScreen.campaignBurst.endDate ?? campaignBurstScreen.campaignBurst.campaign.endDate;
                // add 1 day to endDate
                endDate = moment(endDate).add(1, 'days').format('YYYY-MM-DD');

                // is startDate and endDate in range minDate and maxDate using momentjs
                let filteredReportLinks = this.allReportLinks.filter(item => {
                    let assignedMatch = item.comments.startsWith("Assigned")
                    if(assignedMatch) return true
                    // comments starts with media owner name or assigned
                    let dateMatch = moment(item.minDate).isBetween(moment(startDate), moment(endDate), undefined, '[]')
                    || moment(item.maxDate).isBetween(moment(startDate), moment(endDate), undefined, '[]')
                    || moment(startDate).isBetween(moment(item.minDate), moment(item.maxDate), undefined, '[]')
                    || moment(endDate).isBetween(moment(item.minDate), moment(item.maxDate), undefined, '[]')
                    || moment(item.minDate).isSame(moment(startDate))
                    //|| moment(item.maxDate).isSame(moment(endDate))
                    || startDate == null || endDate == null;
                    let commentsMatch = item.comments.indexOf(campaignBurstScreen.screen.mediaOwner.friendlyName) >= 0
                    return (dateMatch && commentsMatch) || assignedMatch;
                });

                return filteredReportLinks
                };
            },
            itemsSearchFilter(){
                // remove .campaignBurstScreen.campaignBurst.campaign.uniqueScreens
                this.campaignReportLinks.forEach(item => {
                    delete item.campaignBurstScreen.campaignBurst.campaign.uniqueScreens
                })
                return collectionSearch(this.campaignReportLinks, this.searchParams, this.searchCampaign, 0)
            }
        },
        methods: {
            removeChip(){
                /* Find the chip in this.selectedAdCopyId[item] and remove itsith splice */
                let index = this.selectedAdCopyId[this.selectedItem].indexOf(this.selectedChip);
                // If found
                if (index !== -1) {
                    // Remove from selectedAdCopyId
                    this.selectedAdCopyId[this.selectedItem].splice(index, 1);
                    // Create object to remove from database
                    let createObj = {
                        campaignBurstScreenId: this.selectedItem,
                        creativeId: this.selectedChip
                    }
                    // Remove from database using ReportingController
                    ReportingController.removeCampaignReportLink(createObj)
                    .then(() => {
                        this.$root.$emit("snackbarSuccess", "Report link removed successfully")
                        this.removeLinkingDialog = false;
                        this.$nextTick()
                    })
                    .catch(error => {
                        this.$root.$emit("snackbarError", error.response.data.message)
                    })
                }
            },
            removeChipOpenDialog(chip, item) {
                this.removeLinkingDialog = true
                this.selectedChip = chip
                this.selectedItem = item
            },
            selectFirst() {
                if (typeof this.campaign_id === 'string' && this.filteredCampaignItems.length > 0) {
                    this.campaign_id = this.filteredCampaignItems[0];
                    this.campaignChange();
                    this.$refs.campaignsCombobox.blur();
                }
                return;
            },
            async getCampaign(campaignId) {
                // Get campaign
                const { data = null } = await CampaignController.getCampaign(campaignId)
                // Catch error
                .catch(error => { this.$root.$emit("snackbarError", error.response.data.message) })

                // If the response contains valid data then append
                if(data) {
                    this.campaign = data
                    this.bursts = this.campaign.campaignBursts
                }
                else this.campaign = null
            },

            campaignChange() {
                if(typeof this.campaign_id === 'object'){
                    if(this.campaign_id == null) return
                    this.selectedResourceLinkShortName = null
                    this.bursts = []
                    this.screens = []
                    this.burst_id = null
                    this.screen_id = null
                    this.getCampaign(this.campaign_id.id)
                    this.reportUnassignedLinksLoading = true
                    this.getUnassignedReportLinks()
                    this.reportLinksLoading = true
                    this.getReportLinks()
                }
            },
            // Get Unassigned IDs
            getUnassignedReportLinks () {
                ReportingController.getUnassignedReportLinks()
                .then((res) => {
                    this.unassignedReportLinks = res.data.sort((a,b) => (a.comments > b.comments) ? 1 : ((b.comments > a.comments) ? -1 : 0))
                    this.reportUnassignedLinksLoading = false
                    this.allReportLinks.push(...this.unassignedReportLinks)
                    this.allReportLinks = this.allReportLinks.sort((a,b) => (a.comments > b.comments) ? 1 : ((b.comments > a.comments) ? -1 : 0))
                })
                .catch((err) => {
                    this.reportUnassignedLinksLoading = false
                })
            },
            // Get Assigned AdCopyIds/Creative IDs linked to campaigns.
            getReportLinks () {
                let currentCampaign = {campaignId: this.campaign_id.id}
                ReportingController.getReportLinks(currentCampaign)

                .then((res) => {
                    this.assignedReportLinks = []
                    this.campaignReportLinks = res.data
                    // build adCopySelection
                    for (var i = 0; i < this.campaignReportLinks.length; i++) {
                    {
                        var items = [];
                        for (var j = 0; j < this.campaignReportLinks[i].campaignReports.length; j++) {
                            this.campaignReportLinks[i].campaignReports[j].campaignBurstScreen = this.campaignReportLinks[i].campaignBurstScreen;
                            items.push({adCopyId : this.campaignReportLinks[i].campaignReports[j].creativeId, comments: 'Assigned: ' + this.campaignReportLinks[i].campaignReports[j].campaignBurstScreen.campaignBurst.campaign.name + ' : ' + this.campaignReportLinks[i].campaignReports[j].comments})
                        }
                        
                        this.selectedAdCopyId[this.campaignReportLinks[i].campaignBurstScreen.id] = items.map(x => x.adCopyId)
                        this.allReportLinks.unshift(...items)
                        this.assignedReportLinks.unshift(...items);
                    }
                    this.reportLinksLoading = false
                    }
                    this.allReportLinks = this.allReportLinks.sort((a,b) => (a.comments > b.comments) ? 1 : ((b.comments > a.comments) ? -1 : 0))
                    this.assignedReportLinks = this.assignedReportLinks.sort((a,b) => (a.comments > b.comments) ? 1 : ((b.comments > a.comments) ? -1 : 0))
                })
                .catch((err) => {
                    this.reportLinksLoading = false
                })
            },
            checkCreativeId(e, campaignBurstScreenId) {
                // campaignBurstScreenId acts as the index in selectedAdCopyId too
                // has the element been removed?
                let eExpand = e.map(x => (x.adCopyId ? x.adCopyId : x))
                for(var i = 0; i < this.selectedAdCopyId[campaignBurstScreenId].length; i++)
                {
                if(eExpand.indexOf(this.selectedAdCopyId[campaignBurstScreenId][i]) == -1)
                {
                    let deleteObj = {
                    campaignBurstScreenId: campaignBurstScreenId,
                    creativeId: this.selectedAdCopyId[campaignBurstScreenId][i]
                    }
                    ReportingController.removeCampaignReportLink(deleteObj)
                }
                }
                // has the element been added?
                for(var i = 0; i < eExpand.length; i++)
                {
                if(this.selectedAdCopyId[campaignBurstScreenId].indexOf(eExpand[i]) == -1)
                {
                    let createObj = {
                        campaignBurstScreenId: campaignBurstScreenId,
                        creativeId: eExpand[i]
                    }
                    ReportingController.addCampaignReportLink(createObj)
                }
                }
                this.selectedAdCopyId[campaignBurstScreenId] = eExpand
            },
        }
    }
</script>